import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import api from "../../api";
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";
import LinearProgress from "@material-ui/core/LinearProgress";

const styles = (theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
  container: {
    display: "flex",
    gap: theme.spacing(2),
  },
  verticalIconsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  image: {
    borderRadius: 0,
    width: 100,
    height: 100,
    objectFit: "cover",
  },
  icons: {
    borderRadius: 0,
    width: 20,
    height: 20,
    objectFit: "cover",
  },
  iconButton: {
    padding: theme.spacing(0), // adjust padding as needed
  },
});

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

function NewNotification({ openadmin, handleClose, classes, ref }) {
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    title: "",
    body: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleAddHotel = async () => {
    try {
      setLoading(true);
      setError(null);

      // Check if any field is empty and set the corresponding error message
      if (formData.title === "") {
        setError("Notification Title is empty");
        setSnackbarOpen(true);
        return; // Stop submission if error found
      }
      if (formData.body === "") {
        setError("Notification Description is empty");
        setSnackbarOpen(true);
        return; // Stop submission if error found
      }

      const data = {
        title: formData.title,
        body: formData.body,
      };

      const formDataToSend = new FormData();

      formDataToSend.append("title", data.title);
      formDataToSend.append("body", data.body);

      const token = sessionStorage.getItem("id_token");
      const response = await api.post(
        "/admin/send-notifications",
        formDataToSend,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.success) {
        console.log("New notification added:", response.data.data);

        setFormData({
          title: "",
          body: "",
        });

        handleClose(); // Close dialog after successful addition
        setError("New notification added");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarOpen(true);
      console.error("Error adding notification:", error);
      setError("Error adding notification");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Dialog
        open={openadmin}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          New Notification Detail
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            To add a new Notification just enter the following details all are
            required fields.
          </DialogContentText>

          <TextField
            autoFocus
            margin="dense"
            id="notificationtitle"
            label="Title"
            type="text"
            fullWidth
            name="title"
            error={formData.title === ""}
            value={formData.title}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="description"
            label="Description"
            type="text"
            fullWidth
            multiline
            rows={4}
            name="body"
            error={formData.body === ""}
            value={formData.body}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddHotel} color="primary">
            Add New
          </Button>
        </DialogActions>
        {loading && <LinearProgress />}
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        TransitionComponent={TransitionUp}
        message={<span id="message-id">{error}</span>}
        autoHideDuration={6000}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
      />
    </div>
  );
}

export default withStyles(styles)(NewNotification);
