import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import { withStyles } from "@material-ui/core/styles";
import { Box, Paper, Typography } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import api from "../../api";
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";
import LinearProgress from "@material-ui/core/LinearProgress";

const styles = (theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
  container: {
    display: "flex",
    gap: theme.spacing(2),
  },
  verticalIconsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  image: {
    borderRadius: 0,
    width: 100,
    height: 100,
    objectFit: "cover",
  },
  icons: {
    borderRadius: 0,
    width: 20,
    height: 20,
    objectFit: "cover",
  },
  iconButton: {
    padding: theme.spacing(0), // adjust padding as needed
  },
  addicon: {
    fontSize: 16, // adjust the size as needed
  },
  removeicon: {
    fontSize: 16, // adjust the size as needed
  },
});

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

function NewService({ openadmin, handleClose, classes, ref }) {
  // for image preview
  const [state, setstate] = useState("");
  const image = process.env.PUBLIC_URL + "/static/images/logo-dark.png";
  const [selectedFile, setSelectedFile] = useState(null);
  const [categories, setCategories] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    en: "",
    ar: "",
    fr: "",
    ur: "",
    tr: "",
    sw: "",
    men: "",
    mar: "",
    mfr: "",
    mur: "",
    mtr: "",
    msw: "",
    den: "",
    dar: "",
    dfr: "",
    dur: "",
    dtr: "",
    dsw: "",
    lat: "",
    lng: "",
    category_id: "",
    address: "",
    phone_one: "",
    phone_two: "",
    phone_three: "",
  });

  const [phoneErrors, setPhoneErrors] = useState({
    phone_one: false,
    phone_two: false,
    phone_three: false,
  });

  const validatePhoneNumber = (name, value) => {
    if (value.length < 9 || value.length > 20) {
      setPhoneErrors((prevErrors) => ({
        ...prevErrors,
        [name]: true,
      }));
    } else {
      setPhoneErrors((prevErrors) => ({
        ...prevErrors,
        [name]: false,
      }));
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (
      name === "phone_one" ||
      name === "phone_two" ||
      name === "phone_three"
    ) {
      validatePhoneNumber(name, value);
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  var loadFile = (event) => {
    if (event.target.files) {
      const file = event.target.files[0];
      setstate(URL.createObjectURL(event.target.files[0]));
      const fileURL = URL.createObjectURL(event.target.files[0]);
      setSelectedFile(file);
      console.log(fileURL);
    }
  };

  const handleAddHotel = async () => {
    try {
      setLoading(true);
      setError(null);

      // Check if any field is empty and set the corresponding error message
      if (formData.en === "") {
        setError("Service Name in (English) is empty");
        setSnackbarOpen(true);
        return; // Stop submission if error found
      }
      if (formData.ar === "") {
        setError("Service Name in (Arabic) is empty");
        setSnackbarOpen(true);
        return;
      }
      if (formData.fr === "") {
        setError("Service Name in (French) is empty");
        setSnackbarOpen(true);
        return;
      }

      if (formData.ur === "") {
        setError("Service Name in (Urdu) is empty");
        setSnackbarOpen(true);
        return;
      }

      if (formData.tr === "") {
        setError("Service Name in (Turkish) is empty");
        setSnackbarOpen(true);
        return;
      }

      const data = {
        name: {
          en: formData.en,
          ar: formData.ar,
          fr: formData.fr,
          ur: formData.ur,
          tr: formData.tr,
          sw: "",
        },
        mall_name: {
          en: formData.men,
          ar: formData.mar,
          fr: formData.mfr,
          ur: formData.mur,
          tr: formData.mtr,
          sw: "",
        },
        description: {
          en: formData.den,
          ar: formData.dar,
          fr: formData.dfr,
          ur: formData.dur,
          tr: formData.dtr,
          sw: "",
        },
        lat: formData.lat,
        lng: formData.lng,
        category_id: formData.category_id,
        address: formData.address,
        phone_one: formData.phone_one,
        phone_two: formData.phone_two,
        phone_three: formData.phone_three,
      };

      const formDataToSend = new FormData();

      // Append each nested property individually to FormData
      Object.keys(data.name).forEach((key) => {
        formDataToSend.append(`name[${key}]`, data.name[key]);
      });

      Object.keys(data.mall_name).forEach((key) => {
        formDataToSend.append(`mall_name[${key}]`, data.mall_name[key]);
      });

      Object.keys(data.description).forEach((key) => {
        formDataToSend.append(`description[${key}]`, data.description[key]);
      });

      formDataToSend.append("lat", data.lat);
      formDataToSend.append("lng", data.lng);
      formDataToSend.append("category_id", data.category_id);
      formDataToSend.append("address", data.address);
      formDataToSend.append("phone_one", data.phone_one);
      formDataToSend.append("phone_two", data.phone_two);
      formDataToSend.append("phone_three", data.phone_three);

      if (selectedFile) {
        formDataToSend.append("image", selectedFile);

        const token = sessionStorage.getItem("id_token");
        const response = await api.post("/admin/services", formDataToSend, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.data.success) {
          console.log("New restaurants added:", response.data.data);
          setFormData({
            en: "",
            ar: "",
            fr: "",
            ur: "",
            tr: "",
            sw: "",
            men: "",
            mar: "",
            mfr: "",
            mur: "",
            mtr: "",
            msw: "",
            den: "",
            dar: "",
            dfr: "",
            dur: "",
            dtr: "",
            dsw: "",
            lat: "",
            lng: "",
            category_id: "",
            address: "",
            phone_one: "",
            phone_two: "",
            phone_three: "",
          });
          setstate("");
          setSelectedFile(null);
          handleClose(); // Close dialog after successful addition
          setError("New Service added");
          setSnackbarOpen(true);
        }
      } else {
        setError("Kindly select the image file");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarOpen(true);
      console.error("Error adding Service:", error);
      setError("Error adding Service");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const token = sessionStorage.getItem("id_token");
        const response = await api.get("/admin/categories?type=service", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data.success) {
          setCategories(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching Service categories:", error);
      }
    };

    fetchCategories();
  }, []);

  return (
    <div>
      <Dialog
        open={openadmin}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Service Detail</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To add a new Service just enter the following details all are
            required fields
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="hotel-name"
            label="Service Name (English)"
            type="text"
            fullWidth
            name="en"
            error={formData.en === ""}
            value={formData.en}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="hotel-name"
            label="Service Name (Arabic)"
            type="text"
            fullWidth
            name="ar"
            error={formData.ar === ""}
            value={formData.ar}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="hotel-name"
            label="Service Name (French)"
            type="text"
            fullWidth
            name="fr"
            error={formData.fr === ""}
            value={formData.fr}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="hotel-name"
            label="Service Name (Urdu)"
            type="text"
            fullWidth
            name="ur"
            error={formData.ur === ""}
            value={formData.ur}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="hotel-name"
            label="Service Name (Turkish)"
            type="text"
            fullWidth
            error={formData.tr === ""}
            name="tr"
            value={formData.tr}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="hotel-name"
            label="Mall Name (English)"
            type="text"
            fullWidth
            name="men"
            error={formData.men === ""}
            value={formData.men}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="hotel-name"
            label="Mall Name (Arabic)"
            type="text"
            fullWidth
            name="mar"
            error={formData.mar === ""}
            value={formData.mar}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="hotel-name"
            label="Mall Name (French)"
            type="text"
            fullWidth
            name="mfr"
            error={formData.mfr === ""}
            value={formData.mfr}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="hotel-name"
            label="Mall Name (Urdu)"
            type="text"
            fullWidth
            name="mur"
            error={formData.mur === ""}
            value={formData.mur}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="hotel-name"
            label="Mall Name (Turkish)"
            type="text"
            fullWidth
            error={formData.mtr === ""}
            name="mtr"
            value={formData.mtr}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="hotel-description"
            label="Service Description (English)"
            type="text"
            fullWidth
            name="den"
            multiline
            rows={3}
            error={formData.den === ""}
            value={formData.den}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="hotel-description"
            label="Service Description (Arabic)"
            type="text"
            fullWidth
            name="dar"
            multiline
            rows={3}
            error={formData.dar === ""}
            value={formData.dar}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="hotel-description"
            label="Service Description (French)"
            type="text"
            fullWidth
            name="dfr"
            multiline
            rows={3}
            error={formData.dfr === ""}
            value={formData.dfr}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="hotel-description"
            label="Service Description (Urdu)"
            type="text"
            fullWidth
            name="dur"
            multiline
            rows={3}
            error={formData.dur === ""}
            value={formData.dur}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="hotel-description"
            label="Service Description (Turkish)"
            type="text"
            fullWidth
            name="dtr"
            multiline
            rows={3}
            error={formData.dtr === ""}
            value={formData.dtr}
            onChange={handleChange}
          />

          <FormControl variant="standard" fullWidth>
            <InputLabel id="demo-simple-select-standard-label">
              Select Service Category
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              name="category_id"
              onChange={handleChange}
              error={formData.category_id === ""}
              value={formData.category_id}
              label="Category"
            >
              {categories.map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.name.en}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Paper style={{ width: "150px", margin: "10px 0px" }}>
            <Box
              width="140px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <input
                type="file"
                accept="image/*"
                name="image"
                id="file"
                onChange={loadFile}
                style={{ display: "none" }}
              />
              <Typography
                variant="caption"
                style={{
                  fontSize: "12px",
                  padding: "5px 0px",
                  fontWeight: "Bold",
                }}
              >
                Service Icon
              </Typography>
              <img
                src={state ? state : image}
                className={classes.image}
                id="output"
                width="100"
                alt="test"
              />
            </Box>
            <Box display="flex" justifyContent="flex-end" padding="5px 10px">
              <label htmlFor="file" style={{ cursor: "pointer" }}>
                <PhotoCamera />
              </label>
            </Box>
          </Paper>

          <TextField
            margin="dense"
            id="phonenumber1"
            label="Phone Number 1"
            type="number"
            fullWidth
            name="phone_one"
            error={phoneErrors.phone_one}
            helperText={
              phoneErrors.phone_one
                ? "Phone number must be between 9 and 20 digits"
                : ""
            }
            value={formData.phone_one}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="phonenumber2"
            label="Phone Number 2"
            type="number"
            fullWidth
            name="phone_two"
            error={phoneErrors.phone_two}
            helperText={
              phoneErrors.phone_two
                ? "Phone number must be between 9 and 20 digits"
                : ""
            }
            value={formData.phone_two}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="phonenumber3"
            label="Phone Number 3"
            type="number"
            fullWidth
            name="phone_three"
            error={phoneErrors.phone_three}
            helperText={
              phoneErrors.phone_three
                ? "Phone number must be between 9 and 20 digits"
                : ""
            }
            value={formData.phone_three}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="address"
            label="Address"
            type="text"
            fullWidth
            name="address"
            error={formData.address === ""}
            value={formData.address}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="latitude"
            label="latitude"
            type="number"
            fullWidth
            name="lat"
            error={formData.lat === ""}
            value={formData.lat}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="longitude"
            label="longitude"
            type="number"
            fullWidth
            name="lng"
            error={formData.lng === ""}
            value={formData.lng}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddHotel} color="primary">
            Add New
          </Button>
        </DialogActions>
        {loading && <LinearProgress />}
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        TransitionComponent={TransitionUp}
        message={<span id="message-id">{error}</span>}
        autoHideDuration={6000}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
      />
    </div>
  );
}

export default withStyles(styles)(NewService);
