import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import { Box, Paper, Typography } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import api from "../../api";
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

function EditEvent({ open, handleClose, hotelData }) {
  const [state, setstate] = useState("");
  // const image = process.env.PUBLIC_URL + "/static/images/logo-dark.png";
  const image = hotelData.image;
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [formData, setFormData] = useState({
    en: "",
    ar: "",
    fr: "",
    ur: "",
    tr: "",
    sw: "",
    den: "",
    dar: "",
    dfr: "",
    dur: "",
    dtr: "",
    dsw: "",
    lat: "",
    lng: "",
    address: "",
    event_time: "",
    event_date: "",
    phone_one: "",
    phone_two: "",
    phone_three: "",
  });

  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = `${now.getMonth() + 1}`.padStart(2, "0"); // Months are zero-based
    const day = `${now.getDate()}`.padStart(2, "0");
    const hours = `${now.getHours()}`.padStart(2, "0");
    const minutes = `${now.getMinutes()}`.padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const [eventDateTime, setEventDateTime] = useState(getCurrentDateTime());
  const handleDateTimeChange = (event) => {
    setEventDateTime(event.target.value);
  };

  const combineDateTime = (date, time) => {
    return `${date}T${time}`;
  };

  const eventDateTimeFromData = combineDateTime(
    hotelData.event_date,
    hotelData.event_time
  );

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  var loadFile = (event) => {
    if (event.target.files) {
      const file = event.target.files[0];
      setstate(URL.createObjectURL(event.target.files[0]));
      const fileURL = URL.createObjectURL(event.target.files[0]);
      setSelectedFile(file);
      console.log(fileURL);
    }
  };

  const handleUpdateHotel = async () => {
    try {
      setLoading(true);
      setError(null);

      const [eventDate, eventTime] = eventDateTime.split("T");
      const formattedTime = eventTime + ":00"; // Adding seconds

      const data = {
        name: {
          en: formData.en,
          ar: formData.ar,
          fr: formData.fr,
          ur: formData.ur,
          tr: formData.tr,
          sw: "",
        },
        description: {
          en: formData.den,
          ar: formData.dar,
          fr: formData.dfr,
          ur: formData.dur,
          tr: formData.dtr,
          sw: "",
        },
        lat: formData.lat,
        lng: formData.lng,
        address: formData.address,
        event_time: formattedTime,
        event_date: eventDate,
        phone_one: formData.phone_one,
        phone_two: formData.phone_two,
        phone_three: formData.phone_three,
      };

      const formDataToSend = new FormData();

      // Append each nested property individually to FormData
      Object.keys(data.name).forEach((key) => {
        formDataToSend.append(`name[${key}]`, data.name[key]);
      });

      Object.keys(data.description).forEach((key) => {
        formDataToSend.append(`description[${key}]`, data.description[key]);
      });

      formDataToSend.append("lat", data.lat);
      formDataToSend.append("lng", data.lng);
      formDataToSend.append("address", data.address);
      formDataToSend.append("event_time", data.event_time);
      formDataToSend.append("event_date", data.event_date);
      formDataToSend.append("phone_one", data.phone_one);
      formDataToSend.append("phone_two", data.phone_two);
      formDataToSend.append("phone_three", data.phone_three);

      if (selectedFile) {
        formDataToSend.append("image", selectedFile);
      }

      // for (let [key, value] of formDataToSend.entries()) {
      //   console.log(`${key}: ${value}`);
      // }

      const token = sessionStorage.getItem("id_token");
      const response = await api.post(
        `/admin/events/${hotelData.id}`,
        formDataToSend,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.success) {
        console.log("events updated:", response.data.data);
        handleClose();
        setError("events updated successfully");
        setSnackbarOpen(true);
      } else {
        setError("Error updating events");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarOpen(true);
      console.error("Error updating events:", error);
      setError("Error updating events");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // This effect runs when hotelData changes
    if (hotelData) {
      // Set formData based on hotelData when it's available
      setFormData({
        en: hotelData && hotelData.name && hotelData.name.en,
        ar: hotelData && hotelData.name && hotelData.name.ar,
        fr: hotelData && hotelData.name && hotelData.name.fr,
        ur: hotelData && hotelData.name && hotelData.name.ur,
        tr: hotelData && hotelData.name && hotelData.name.tr,
        sw: hotelData && hotelData.name && hotelData.name.sw,
        den: hotelData && hotelData.description && hotelData.description.en,
        dar: hotelData && hotelData.description && hotelData.description.ar,
        dfr: hotelData && hotelData.description && hotelData.description.fr,
        dur: hotelData && hotelData.description && hotelData.description.ur,
        dtr: hotelData && hotelData.description && hotelData.description.tr,
        dsw: hotelData && hotelData.description && hotelData.description.sw,
        address: hotelData.address,
        event_time: hotelData.event_time,
        event_date: hotelData.event_date,
        lat: hotelData.lat,
        lng: hotelData.lng,
        phone_one: hotelData.phone_one,
        phone_two: hotelData.phone_two,
        phone_three: hotelData.phone_three,
        image: hotelData.image,
      });
    }
  }, [hotelData]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Edit Event Detail</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You can edit important Event details or delete and add it again.
          </DialogContentText>
          <TextField
            id="datetime-local"
            label="Event Date,  Time"
            type="datetime-local"
            defaultValue={eventDateTimeFromData}
            onChange={handleDateTimeChange}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            autoFocus
            margin="dense"
            id="shop-name"
            label="Event Name in English"
            type="text"
            fullWidth
            name="en"
            defaultValue={hotelData && hotelData.name && hotelData.name.en}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="shop-name"
            label="Event Name in Arabic"
            type="text"
            fullWidth
            name="ar"
            defaultValue={hotelData && hotelData.name && hotelData.name.ar}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="shop-name"
            label="Event Name in French"
            type="text"
            fullWidth
            name="fr"
            defaultValue={hotelData && hotelData.name && hotelData.name.fr}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="shop-name"
            label="Event Name in Urdu"
            type="text"
            fullWidth
            name="ur"
            defaultValue={hotelData && hotelData.name && hotelData.name.ur}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="shop-name"
            label="Event Name in Turkish"
            type="text"
            fullWidth
            name="tr"
            defaultValue={hotelData && hotelData.name && hotelData.name.tr}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="hotel-description"
            label="Event Description (English)"
            type="text"
            fullWidth
            name="den"
            multiline
            rows={3}
            defaultValue={
              hotelData && hotelData.description && hotelData.description.en
            }
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="hotel-description"
            label="Event Description (Arabic)"
            type="text"
            fullWidth
            name="dar"
            multiline
            rows={3}
            defaultValue={
              hotelData && hotelData.description && hotelData.description.ar
            }
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="hotel-description"
            label="Event Description (French)"
            type="text"
            fullWidth
            name="dfr"
            multiline
            rows={3}
            defaultValue={
              hotelData && hotelData.description && hotelData.description.fr
            }
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="hotel-description"
            label="Event Description (Urdu)"
            type="text"
            fullWidth
            name="dur"
            multiline
            rows={3}
            defaultValue={
              hotelData && hotelData.description && hotelData.description.ur
            }
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="hotel-description"
            label="Event Description (Turkish)"
            type="text"
            fullWidth
            name="dtr"
            multiline
            rows={3}
            defaultValue={
              hotelData && hotelData.description && hotelData.description.tr
            }
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="address"
            label="Address"
            type="text"
            fullWidth
            name="address"
            defaultValue={hotelData && hotelData.address}
            onChange={handleChange}
          />

          <Paper style={{ width: "150px", margin: "10px 0px" }}>
            <Box
              width="140px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <input
                type="file"
                accept="image/*"
                name="image"
                id="file"
                onChange={loadFile}
                style={{ display: "none" }}
              />
              <Typography
                variant="caption"
                style={{
                  fontSize: "12px",
                  padding: "5px 0px",
                  fontWeight: "Bold",
                }}
              >
                Event Main Image *
              </Typography>

              <img
                src={state ? state : image}
                id="output"
                width="100"
                name="outputmain"
                alt="test"
              />
            </Box>
            <Box display="flex" justifyContent="flex-end" padding="5px 10px">
              <label htmlFor="file" style={{ cursor: "pointer" }}>
                <PhotoCamera />
              </label>
            </Box>
          </Paper>

          <TextField
            margin="dense"
            id="latitude"
            label="Latitude"
            type="number"
            fullWidth
            name="lat"
            defaultValue={hotelData && hotelData.lat}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="longitude"
            label="Longitude"
            type="number"
            fullWidth
            name="lng"
            defaultValue={hotelData && hotelData.lng}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="phonenumber1"
            label="Phone Number 1"
            type="number"
            fullWidth
            name="phone_one"
            defaultValue={hotelData && hotelData.phone_one}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="phonenumber2"
            label="Phone Number 2"
            type="number"
            fullWidth
            name="phone_two"
            defaultValue={hotelData && hotelData.phone_two}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="phonenumber3"
            label="Phone Number 3"
            type="number"
            fullWidth
            name="phone_three"
            defaultValue={hotelData && hotelData.phone_three}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpdateHotel} color="primary">
            Update
          </Button>
        </DialogActions>
        {loading && <LinearProgress />}
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        TransitionComponent={TransitionUp}
        message={error}
        key="snackbar"
        autoHideDuration={6000}
      />
    </div>
  );
}

export default EditEvent;
