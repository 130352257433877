import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import TablePagination from '@material-ui/core/TablePagination';
import { Wrapper } from '../../../components';
import EditUser from './EditAdmin'; // Import the EditUser component 
import NewAdmin from './NewAdmin'; // Import the EditUser component
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import api from "../../../api";
import LinearProgress from "@material-ui/core/LinearProgress";

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: "#9da07c",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const styles = theme => ({
  textAlign:{
   textAlign: 'right',
  },
  button: {
    margin: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  root: {
    width: '100%',
    marginTop: theme.spacing(1) * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  appBar: {
    marginBottom: theme.spacing(1) * 3,
  },
  flexSpacer: {
    flex: 1,
  },
});

function Admins(props) {
  const { classes } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [open, setOpen] = useState(false);
  const [openadmin, setadminOpen] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false); // State to control delete confirmation dialog
  const [hotels, setHotels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hotelData, sethotelData] = useState(1);
  const [deleteHotelId, setDeleteHotelId] = useState(null);


   


  const handleDeleteClick = async (hotelId) => {
    setDeleteHotelId(hotelId);
    setDeleteConfirmationOpen(true);
  };
  const handleDeleteConfirm = async () => {
    try {
      const token = sessionStorage.getItem("id_token");
      const response = await api.delete(`/admin/administrators/${deleteHotelId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.success) {
        // Handle success response, maybe show a success message or update UI accordingly
        console.log("Admin deleted successfully");
      } else {
        // Handle error response if needed
        console.error("Error deleting admin:", response.data.error);
      }
    } catch (error) {
      console.error("Error deleting admin:", error);
    }
    handleDeleteConfirmationClose();
  };

  const handleDeleteConfirmationClose = () => {
    setDeleteConfirmationOpen(false);
  };

  
  const handleNewAdminClick = () => {
    setadminOpen(true); // Open the dialog when edit icon is clicked
  };

  const handleEditClick = async (hotelId) => {
    try {
      const token = sessionStorage.getItem("id_token");
      const response = await api.get(`/admin/administrators/${hotelId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.success) {
        // Here you can handle the response, maybe set it to some state
        sethotelData(response.data.data);
        console.log(response.data.data);
      } else {
        // Handle the error response if needed
        console.error("Error fetching User data:", response.data.error);
      }
    } catch (error) {
      console.error("Error fetching User data:", error);
    }
    setOpen(true); // Open the dialog when edit icon is clicked
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredHotels = hotels.filter((hotel) =>
  hotel.name.toLowerCase()
);



  useEffect(() => {
    setLoading(true);
    const fetchHotels = async () => {
      try {
        const token = sessionStorage.getItem("id_token");
        const response = await api.get("/admin/administrators", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.success) {
          setHotels(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching admins:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchHotels();
  }, [deleteConfirmationOpen, openadmin, open]);

  return (
    <Wrapper>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" color="inherit" className={classes.flexSpacer}>
            Admin Information
          </Typography>
           
          <Button variant="contained" color="secondary" className={classes.button} onClick={() => handleNewAdminClick()}>
        New
        <AddIcon className={classes.rightIcon} />
      </Button>

        </Toolbar>
      </AppBar>
      <Paper className={classes.root}>
      {loading && <LinearProgress />}
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <CustomTableCell  >S.No</CustomTableCell>
              <CustomTableCell>Name</CustomTableCell>
              <CustomTableCell  >Email</CustomTableCell>
              <CustomTableCell   className={classes.textAlign}>Action</CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {filteredHotels
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((hotel, index) => (
              <TableRow className={classes.row} key={hotel.id}>
                <CustomTableCell  >{hotel.id}</CustomTableCell>
                <CustomTableCell component="th" scope="row">
                  {hotel.name}
                </CustomTableCell>
                <CustomTableCell  >{hotel.email}</CustomTableCell>
                <CustomTableCell   className={classes.textAlign}>
                  <Button variant="contained" color="primary" className={classes.button} onClick={() => handleEditClick(hotel.id)}>
                      Edit
                <EditIcon className={classes.rightIcon} />
                    </Button>
                   {/* Pass the function to open the dialog */}
                  <Button disabled={hotel.id === 1} variant="contained" color="secondary" className={classes.button} onClick={() => handleDeleteClick(hotel.id)}>
                      Delete
                <DeleteIcon className={classes.rightIcon} />
                    </Button>
                </CustomTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredHotels.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <Dialog
        open={deleteConfirmationOpen}
        onClose={handleDeleteConfirmationClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this admin?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteConfirmationClose} color="primary">
            No
          </Button>
          <Button onClick={handleDeleteConfirm} color="secondary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <EditUser open={open} setOpen={setOpen} handleClose={() => setOpen(false)} hotelData={hotelData}  /> {/* Pass open state and setOpen function to EditUser */}
      <NewAdmin openadmin={openadmin} setadminOpen={setadminOpen} handleClose={() => setadminOpen(false)}  />
    </Wrapper>
  );
}

Admins.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Admins);
