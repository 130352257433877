import React, { useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Wrapper } from "../../components";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import TablePagination from "@material-ui/core/TablePagination";
import TableCell from "@material-ui/core/TableCell";
import Input from "@material-ui/core/Input";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import NewNotification from "./NewNotification";
import EditNotification from "./EditNotification";

const CustomTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#9da07c",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const styles = (theme) => ({
  root: {
    paddingTop: theme.spacing(1) * 2,
    paddingBottom: theme.spacing(1) * 2,
    width: "100%",
    marginTop: theme.spacing(1) * 3,
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  iconButton: {
    padding: theme.spacing(0.5), // adjust padding as needed
  },
  cover: {
    width: 40,
    height: 25,
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
  appBar: {
    marginBottom: theme.spacing(1) * 3,
  },
  flexSpacer: {
    flex: 1,
  },
  searchInput: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: "200px",
    color: "white",
    "&:before": {
      borderBottom: "1px solid white",
    },
  },
});

function Notifications(props) {
  const { classes } = props;
  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [open, setOpen] = useState(false);
  const [openadmin, setadminOpen] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  const handleNewAdminClick = () => {
    setadminOpen(true); // Open the dialog when edit icon is clicked
  };

  const handleDeleteClick = (userId) => {
    setDeleteConfirmationOpen(true);
  };
  const handleDeleteConfirm = () => {
    // Perform delete operation here
    // Close the delete confirmation dialog
    handleDeleteConfirmationClose();
  };

  const handleDeleteConfirmationClose = () => {
    setDeleteConfirmationOpen(false);
  };

  const handleEditClick = (userId) => {
    setOpen(true); // Open the dialog when edit icon is clicked
  };

  let id = 0;
  function createData(eventname, description, action) {
    id += 1;
    return { id, eventname, description, action };
  }

  const rows = [
    createData(
      "The Ministry of Finance approval",
      "To restructure an existing SAR 3 billion Murabaha loan facility.",
      "Action 1"
    ),
    createData(
      "Company announces that it has received, on Monday 23",
      "The Submission of the Application for the Increase of the Company",
      "Action 2"
    ),
    createData(
      "New Jabal Omar project in Makkah",
      "A new luxury hotel and serviced apartments complex in the heart of Makkah.",
      "Action 3"
    ),
    createData(
      "New CFO of Jabal Omar",
      "Hani Abdulwahab Zahran as the companys chief finance officer",
      "Action 4"
    ),
    createData(
      "General manager of Jabal Omar Jumeirah",
      "Recently appointed General Manager Rizwan Shaikh",
      "Action 5"
    ),
  ];

  const filteredRows = rows.filter((row) =>
    row.eventname.toLowerCase().includes(searchInput.toLowerCase())
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  return (
    <div>
      <Wrapper>
        <AppBar position="static" className={classes.appBar}>
          <Toolbar>
            <Typography
              variant="h6"
              color="inherit"
              className={classes.flexSpacer}
            >
              Notifications
            </Typography>
            <Input
              placeholder="Notifications Name..."
              className={classes.searchInput}
              value={searchInput}
              onChange={handleSearchInputChange}
            />
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={() => handleNewAdminClick()}
            >
              New
              <AddIcon className={classes.rightIcon} />
            </Button>
          </Toolbar>
        </AppBar>
        <Paper className={classes.root} elevation={1}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <CustomTableCell>S.No</CustomTableCell>
                <CustomTableCell>Title</CustomTableCell>
                <CustomTableCell>Description</CustomTableCell>
                <CustomTableCell>Action</CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow className={classes.row} key={row.id}>
                    <CustomTableCell>{row.id}</CustomTableCell>
                    <CustomTableCell component="th" scope="row">
                      {row.eventname}
                    </CustomTableCell>
                    <CustomTableCell>{row.description}</CustomTableCell>
                    <CustomTableCell>
                      <IconButton
                        className={classes.iconButton}
                        onClick={() => handleEditClick()}
                      >
                        <EditIcon color="primary" />{" "}
                        {/* Pass the function to open the dialog */}
                      </IconButton>
                      <IconButton
                        className={classes.iconButton}
                        onClick={() => handleDeleteClick()}
                      >
                        <DeleteIcon color="secondary" />
                      </IconButton>
                    </CustomTableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>

        <Dialog
          open={deleteConfirmationOpen}
          onClose={handleDeleteConfirmationClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this Notification?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteConfirmationClose} color="primary">
              No
            </Button>
            <Button onClick={handleDeleteConfirm} color="secondary" autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <EditNotification
          open={open}
          setOpen={setOpen}
          handleClose={() => setOpen(false)}
        />
        <NewNotification
          openadmin={openadmin}
          setadminOpen={setadminOpen}
          handleClose={() => setadminOpen(false)}
        />
      </Wrapper>
    </div>
  );
}

Notifications.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Notifications);
