import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import { withStyles } from "@material-ui/core/styles";
import { Box, Paper, Typography } from "@material-ui/core";
import api from "../../api";
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";
import LinearProgress from "@material-ui/core/LinearProgress";

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}
const styles = (theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
  container: {
    display: "flex",
    gap: theme.spacing(2),
  },
  verticalIconsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  image: {
    borderRadius: 0,
    width: 100,
    height: 100,
    objectFit: "cover",
  },
  icons: {
    borderRadius: 0,
    width: 20,
    height: 20,
    objectFit: "cover",
  },
  iconButton: {
    padding: theme.spacing(0), // adjust padding as needed
  },
  addicon: {
    fontSize: 16, // adjust the size as needed
  },
  removeicon: {
    fontSize: 16, // adjust the size as needed
  },
});

function NewNews({ openadmin, handleClose, classes, ref }) {
  // for image preview
  const [state, setstate] = useState("");
  const image = process.env.PUBLIC_URL + "/static/images/logo-dark.png";
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    en: "",
    ar: "",
    fr: "",
    ur: "",
    tr: "",
    sw: "",
    den: "",
    dar: "",
    dfr: "",
    dur: "",
    dtr: "",
    dsw: "",
    date: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  var loadFile = (event) => {
    if (event.target.files) {
      const file = event.target.files[0];
      setstate(URL.createObjectURL(event.target.files[0]));
      const fileURL = URL.createObjectURL(event.target.files[0]);
      setSelectedFile(file);
      console.log(fileURL);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleAddHotel = async () => {
    try {
      setLoading(true);
      setError(null);

      // Check if any field is empty and set the corresponding error message
      if (formData.en === "") {
        setError("News Title (English) is empty");
        setSnackbarOpen(true);
        return; // Stop submission if error found
      }
      if (formData.ar === "") {
        setError("News Title (Arabic) is empty");
        setSnackbarOpen(true);
        return;
      }
      if (formData.fr === "") {
        setError("News Title (French) is empty");
        setSnackbarOpen(true);
        return;
      }
      if (formData.ur === "") {
        setError("News Title (Urdu) is empty");
        setSnackbarOpen(true);
        return;
      }
      if (formData.tr === "") {
        setError("News Title (Turkish) is empty");
        setSnackbarOpen(true);
        return;
      }

      const data = {
        title: {
          en: formData.en,
          ar: formData.ar,
          fr: formData.fr,
          ur: formData.ur,
          tr: formData.tr,
          sw: "",
        },
        content: {
          en: formData.den,
          ar: formData.dar,
          fr: formData.dfr,
          ur: formData.dur,
          tr: formData.dtr,
          sw: "",
        },
        date: formData.date,
      };

      const formDataToSend = new FormData();

      // Append each nested property individually to FormData
      Object.keys(data.title).forEach((key) => {
        formDataToSend.append(`title[${key}]`, data.title[key]);
      });

      Object.keys(data.content).forEach((key) => {
        formDataToSend.append(`content[${key}]`, data.content[key]);
      });

      formDataToSend.append("date", data.date);

      if (selectedFile) {
        formDataToSend.append("image", selectedFile);

        const token = sessionStorage.getItem("id_token");
        const response = await api.post("/admin/news", formDataToSend, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.data.success) {
          console.log("New news added:", response.data.data);
          setFormData({
            en: "",
            ar: "",
            fr: "",
            ur: "",
            tr: "",
            sw: "",
            den: "",
            dar: "",
            dfr: "",
            dur: "",
            dtr: "",
            dsw: "",
            date: "",
          });
          setstate("");
          setSelectedFile(null);
          handleClose(); // Close dialog after successful addition
          setError("New News added");
          setSnackbarOpen(true);
        }
      } else {
        setError("Kindly select the image file");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarOpen(true);
      console.error("Error adding News:", error);
      setError("Error adding News");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Dialog
        open={openadmin}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">New News Detail</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To add a new News just enter the following details all are required
            fields
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="hotel-name"
            label="News Title (English)"
            type="text"
            fullWidth
            name="en"
            error={formData.en === ""}
            value={formData.en}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="hotel-name"
            label="News Title (Arabic)"
            type="text"
            fullWidth
            name="ar"
            error={formData.ar === ""}
            value={formData.ar}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="hotel-name"
            label="News Title (French)"
            type="text"
            fullWidth
            name="fr"
            error={formData.fr === ""}
            value={formData.fr}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="hotel-name"
            label="News Title (Urdu)"
            type="text"
            fullWidth
            name="ur"
            error={formData.ur === ""}
            value={formData.ur}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="hotel-name"
            label="News Title (Turkish)"
            type="text"
            fullWidth
            error={formData.tr === ""}
            name="tr"
            value={formData.tr}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="hotel-description"
            label="News Description (English)"
            type="text"
            fullWidth
            name="den"
            multiline
            rows={3}
            error={formData.den === ""}
            value={formData.den}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="hotel-description"
            label="News Description (Arabic)"
            type="text"
            fullWidth
            name="dar"
            multiline
            rows={3}
            error={formData.dar === ""}
            value={formData.dar}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="hotel-description"
            label="News Description (French)"
            type="text"
            fullWidth
            name="dfr"
            multiline
            rows={3}
            error={formData.dfr === ""}
            value={formData.dfr}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="hotel-description"
            label="News Description (Urdu)"
            type="text"
            fullWidth
            name="dur"
            multiline
            rows={3}
            error={formData.dur === ""}
            value={formData.dur}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="hotel-description"
            label="News Description (Turkish)"
            type="text"
            fullWidth
            name="dtr"
            multiline
            rows={3}
            error={formData.dtr === ""}
            value={formData.dtr}
            onChange={handleChange}
          />

          <Paper style={{ width: "150px", margin: "10px 0px" }}>
            <Box
              width="140px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <input
                type="file"
                accept="image/*"
                name="image"
                id="file"
                onChange={loadFile}
                style={{ display: "none" }}
              />
              <Typography
                variant="caption"
                style={{
                  fontSize: "12px",
                  padding: "5px 0px",
                  fontWeight: "Bold",
                }}
              >
                News Image
              </Typography>
              <img
                src={state ? state : image}
                className={classes.image}
                id="output"
                width="100"
                alt="test"
              />
            </Box>
            <Box display="flex" justifyContent="flex-end" padding="5px 10px">
              <label htmlFor="file" style={{ cursor: "pointer" }}>
                <PhotoCamera />
              </label>
            </Box>
          </Paper>

          <TextField
            margin="dense"
            id="address"
            type="date"
            fullWidth
            name="date"
            error={formData.date === ""}
            value={formData.date}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddHotel} color="primary">
            Add New
          </Button>
        </DialogActions>
        {loading && <LinearProgress />}
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        TransitionComponent={TransitionUp}
        message={<span id="message-id">{error}</span>}
        autoHideDuration={6000}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
      />
    </div>
  );
}

export default withStyles(styles)(NewNews);
