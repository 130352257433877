// chat.js
import React, { useState, useEffect } from "react";
import api from "../../api";
import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import MenuIcon from "@material-ui/icons/Menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SendIcon from "@material-ui/icons/Send";
import TextField from "@material-ui/core/TextField";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Wrapper } from "../../components";
import classNames from "classnames";
import distanceInWordsToNow from "date-fns/distance_in_words_to_now";
import { drawerWidth } from "../../styleVariables";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
  },
  header: {
    marginTop: "-72px",
    padding: "8px",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    position: "relative",
    boxShadow: "0 1px 8px rgba(0,0,0,.3)",
  },
  toolBar: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(1) / 2,
      paddingRight: theme.spacing(1) / 2,
    },
  },
  drawerPaper: {
    position: "relative",
    width: drawerWidth,
    maxWidth: drawerWidth,
    overflow: "auto",
    height: "100%",
  },
  modal: {
    [theme.breakpoints.down("sm")]: {
      top: "56px",
    },
    [theme.breakpoints.up("sm")]: {
      top: "64px",
    },
    zIndex: "1000",
  },
  backdrop: {
    [theme.breakpoints.down("sm")]: {
      top: "56px",
    },
    [theme.breakpoints.up("sm")]: {
      top: "64px",
    },
  },
  headerLeft: {
    position: "relative",
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      maxWidth: drawerWidth,
    },
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth / 2,
      maxWidth: drawerWidth / 2,
    },
    [theme.breakpoints.down("sm")]: {
      marginRight: "-12px",
    },
    display: "flex",
    alignItems: "center",
    overflow: "auto",
    height: "100%",
  },
  wrapper: {
    width: "100%",
    height: "calc(100vh - 208px)",
    zIndex: 1,
    display: "flex",
    position: "relative",
    overflow: "hidden",
    maxWidth: "100%",
    flexDirection: "row",
  },
  main: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "calc(100% - 240px)",
    },
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1),
    minWidth: 0,
    height: "calc(100% - 75px)",
    boxSizing: "border-box",
    flex: 1,
    position: "relative",
    overflowX: "hidden",
    overflowY: "auto",
  },
  conversation: {
    boxSizing: "border-box",
    width: "100%",
    marginBottom: theme.spacing(1) * 2,
    [theme.breakpoints.down("sm")]: {
      padding: `0 ${theme.spacing(1) * 1}px`,
    },
    [theme.breakpoints.up("sm")]: {
      padding: `0 ${theme.spacing(1) * 3}px`,
    },
    display: "flex",
  },
  conversationSent: {
    justifyContent: "flex-end",
  },
  body: {
    position: "relative",
    padding: ".625rem 1rem",
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    color: "white",
  },
  bodyReceived: {
    "&::after": {
      position: "absolute",
      top: 0,
      width: 0,
      height: 0,
      content: '""',
      border: `5px solid ${theme.palette.primary.main}`,
      borderBottomColor: "transparent",
      left: "-7px",
      borderLeftColor: "transparent",
    },
  },
  bodySent: {
    position: "relative",
    backgroundColor: theme.palette.secondary.main,
    float: "right",
    order: 1,
    "&::after": {
      position: "absolute",
      bottom: 0,
      width: 0,
      height: 0,
      content: '""',
      border: `5px solid ${theme.palette.secondary.main}`,
      borderTopColor: "transparent",
      borderRightColor: "transparent",
      right: "-7px",
    },
  },
  date: {
    display: "block",
    fontSize: "11px",
    paddingTop: "2px",
    fontStyle: "italic",
    fontWeight: "600",
    color: theme.palette.primary.contrastText,
  },
  dateSent: {
    textAlign: "right",
  },
  input: {
    flex: "1 1 0%",
    boxSizing: "border-box",
  },
  selectedConversation: {
    backgroundColor: theme.palette.action.selected, // or any other color you prefer
  },
  listItem: {
    textTransform: "capitalize",
    padding: "6px 24px",
    [theme.breakpoints.up("md")]: {
      paddingLeft: "50px",
      paddingRight: "50px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "60px",
      paddingRight: "60px",
    },
  },
  capitalize: {
    textTransform: "capitalize",
    padding: "6px 24px",
  },
  avatar: {
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    lineHeight: "40px",
    textAlign: "center",
    fontSize: "1.2rem",
    border: "2px solid #000",
  },
}));

const Chat = () => {
  const classes = useStyles();
  const [opened, setOpened] = useState(false);

  const handleDrawerToggle = () => setOpened(!opened);
  const [conversations, setConversations] = useState([]);
  const [selectedConversationId, setSelectedConversation] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [customerName, setCustomerName] = useState(null);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    messageInput: "",
  });

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleConversationSelect = async (conversationId) => {
    try {
      setLoading(true);
      const token = sessionStorage.getItem("id_token");
      const response = await api.get("admin/get-all-conversations", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.success) {
        const conversation = response.data.data.data.find(
          (conv) => conv.id === conversationId
        );

        if (conversation) {
          setSelectedConversation(conversationId);
          setCustomerId(conversation.customer_id);
          setCustomerName(conversation.customer.name);
        } else {
          console.error("Conversation not found");
        }
      }
    } catch (error) {
      console.error("Error fetching conversations", error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSendMessage = async () => {
    try {
      setLoading(true);
      setError(null);
      const data = {
        customer_id: customerId,
        message: formData.messageInput,
      };
      const formDataToSend = new FormData();
      formDataToSend.append("customer_id", data.customer_id);
      formDataToSend.append("message", data.message);

      const token = sessionStorage.getItem("id_token");
      const response = await api.post(`admin/send-message`, formDataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.success) {
        setError("Message sent successfully");
        setSnackbarOpen(true);
        // Update messages state with the new message
        const newMessage = response.data.data;
        if (newMessage)
          // Clear message input after sending
          setFormData({
            messageInput: "",
          });

        // Refresh the selectedConversationId state by temporarily setting it to null
        setSelectedConversation(null);

        // Delay setting the selectedConversationId back to its original value
        setTimeout(() => {
          setSelectedConversation(selectedConversationId);
        }, 100);
      }
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchConversations = async () => {
      try {
        setLoading(true);
        const token = sessionStorage.getItem("id_token");
        const response = await api.get(`admin/get-all-conversations`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data.success) {
          // Sort conversations by the timestamp of the latest message in descending order
          const sortedConversations = response.data.data.data.sort((a, b) => {
            const latestMessageTimeA = new Date(
              a.latest_message.created_at
            ).getTime();
            const latestMessageTimeB = new Date(
              b.latest_message.created_at
            ).getTime();
            return latestMessageTimeB - latestMessageTimeA;
          });

          setConversations(sortedConversations);
          // setSelectedConversation(sortedConversations[0].id);
        }
      } catch (error) {
        console.error("Error fetching conversations", error);
      } finally {
        setLoading(false);
      }
    };
    fetchConversations();

    const fetchMessages = async (conversationId) => {
      try {
        setLoading(true);
        const token = sessionStorage.getItem("id_token");
        const response = await api.get(
          `admin/get-conversation-chats/${conversationId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.success) {
          // Map API response to format expected by the component
          const formattedMessages = response.data.data.data.map((chat) => ({
            message: chat.message,
            type: chat.sender_type === "admin" ? "sent" : "received",
            date: new Date(chat.created_at), // Convert string date to Date object
          }));

          setMessages(formattedMessages.reverse());
        }
      } catch (error) {
        console.error("Error fetching messages:", error);
      } finally {
        setLoading(false);
      }
    };

    if (selectedConversationId !== null) {
      fetchMessages(selectedConversationId);
    }
  }, [selectedConversationId]);

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    } else {
      return text;
    }
  };

  const menu = (
    <List subheader={<ListSubheader disableSticky>Contacts</ListSubheader>}>
      {conversations.map((conversation) => (
        <ListItem
          key={conversation.id}
          button
          onClick={() => handleConversationSelect(conversation.id)}
          className={
            conversation.id === selectedConversationId
              ? classes.selectedConversation
              : null
          }
        >
          <div className={classes.avatar}>
            {conversation.customer && conversation.customer.name
              ? conversation.customer.name.charAt(0).toUpperCase()
              : ""}
          </div>

          <ListItemText
            className={classes.capitalize}
            primary={
              conversation.customer && conversation.customer.name
                ? truncateText(conversation.customer.name, 9)
                : "Unknown"
            }
            secondary={
              conversation.latest_message && conversation.latest_message.message
                ? truncateText(conversation.latest_message.message, 15)
                : "No message"
            }
          />
          {/* {conversation.id} */}
          {/* {customerId} */}
        </ListItem>
      ))}
    </List>
  );
  return (
    <Wrapper padding={false}>
      <AppBar position="static">
        <Toolbar />
        <Toolbar />
      </AppBar>

      <Grid container spacing={0} justify={"center"} className={classes.header}>
        <Grid item xs={11} sm={11} md={10} lg={9}>
          <Card>
            <div className={classes.root}>
              <AppBar
                position="absolute"
                className={classes.appBar}
                color="default"
              >
                {loading && <LinearProgress />}
                <Toolbar className={classes.toolBar}>
                  <Hidden mdUp>
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={() => handleDrawerToggle()}
                    >
                      <MenuIcon />
                    </IconButton>
                  </Hidden>
                  <div className={classes.headerLeft}>
                    <Avatar
                      alt=""
                      src={`${process.env.PUBLIC_URL}/static/images/logo-dark.png`}
                    />
                  </div>

                  <List dense>
                    {customerName && (
                      <ListItem>
                        <div className={classes.avatar}>
                          {customerName.charAt(0).toUpperCase()}
                        </div>
                        <ListItemText
                          primary={customerName}
                          // secondary="Online"
                          className={classes.listItem}
                        />
                      </ListItem>
                    )}
                  </List>

                  <span className="flexSpacer" />
                  <IconButton color="inherit">
                    <MoreVertIcon />
                  </IconButton>
                </Toolbar>
              </AppBar>
              <div className={classes.wrapper}>
                <Hidden smDown>
                  <Drawer
                    variant="permanent"
                    ModalProps={{
                      keepMounted: false,
                      className: classes.modal,
                      BackdropProps: {
                        className: classes.backdrop,
                      },
                      onBackdropClick: handleDrawerToggle,
                    }}
                    classes={{
                      paper: classes.drawerPaper,
                    }}
                  >
                    {menu}
                  </Drawer>
                </Hidden>
                <Hidden mdUp>
                  <Drawer
                    variant="temporary"
                    open={opened}
                    ModalProps={{
                      keepMounted: false,
                      className: classes.modal,
                      BackdropProps: {
                        className: classes.backdrop,
                      },
                      onBackdropClick: handleDrawerToggle,
                    }}
                    classes={{
                      paper: classes.drawerPaper,
                    }}
                  >
                    {menu}
                  </Drawer>
                </Hidden>
                <main className={classes.main}>
                  <div className={classes.content}>
                    {messages.map((message, index) => (
                      <div
                        key={index}
                        className={classNames(
                          classes.conversation,
                          message.type === "sent"
                            ? classes.conversationSent
                            : classes.conversationReceived
                        )}
                      >
                        <div
                          className={classNames(
                            classes.body,
                            message.type === "sent"
                              ? classes.bodySent
                              : classes.bodyReceived
                          )}
                        >
                          <Typography color="inherit">
                            {message.message}
                          </Typography>
                          <Typography
                            variant="caption"
                            className={classNames(
                              classes.date,
                              message.type === "sent"
                                ? classes.dateSent
                                : classes.dateReceived
                            )}
                          >
                            {distanceInWordsToNow(message.date)}
                          </Typography>
                        </div>
                      </div>
                    ))}
                  </div>
                  <Divider />
                  <div className="px-2">
                    <Grid
                      container
                      spacing={0}
                      justify={"center"}
                      alignItems={"center"}
                    >
                      <TextField
                        label="Write a message"
                        type="text"
                        margin="normal"
                        name="messageInput"
                        error={formData.messageInput === ""}
                        value={formData.messageInput}
                        onChange={handleChange}
                        className={classes.input}
                        disabled={selectedConversationId === null}
                      />
                      <Fab
                        color="primary"
                        aria-label="send"
                        className={classes.button}
                        disabled={selectedConversationId === null}
                        onClick={handleSendMessage}
                      >
                        <SendIcon />
                      </Fab>
                    </Grid>
                  </div>
                </main>
              </div>
            </div>
          </Card>
        </Grid>
      </Grid>
      <Snackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        TransitionComponent={TransitionUp}
        message={<span id="message-id">{error}</span>}
        autoHideDuration={6000}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
      />
    </Wrapper>
  );
};

export default Chat;
