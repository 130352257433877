const sharedOptions = {
  maintainAspectRatio: true,
  responsive: true,
  legend: {
    display: false,
  },
};

const gridOptions = {
  scales: {
    xAxes: [
      {
        gridLines: {
          color: "rgba(0,0,0,0.02)",
          zeroLineColor: "rgba(0,0,0,0.02)",
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          color: "rgba(0,0,0,0.02)",
          zeroLineColor: "rgba(0,0,0,0.02)",
        },
        position: "left",
        ticks: {
          beginAtZero: true,
          suggestedMax: 9,
        },
      },
    ],
  },
};

const stackedGridOptions = {
  scales: {
    xAxes: [
      {
        gridLines: {
          color: "rgba(0,0,0,0.02)",
          zeroLineColor: "rgba(0,0,0,0.02)",
        },
        stacked: true,
      },
    ],
    yAxes: [
      {
        gridLines: {
          color: "rgba(0,0,0,0.02)",
          zeroLineColor: "rgba(0,0,0,0.02)",
        },
        stacked: true,
        position: "left",
        ticks: {
          beginAtZero: true,
          suggestedMax: 9,
        },
      },
    ],
  },
};

const colors = [
  {
    backgroundColor: "#364B5F",
    borderColor: "#374c5f",
    pointBackgroundColor: "#374c5f",
    pointBorderColor: "#fff",
  },
  {
    backgroundColor: "#eeeeee",
    borderColor: "#e0e0e0",
    pointBackgroundColor: "#e0e0e0",
    pointBorderColor: "#fff",
  },
  {
    backgroundColor: "rgba(148,159,177,0.2)",
    borderColor: "rgba(148,159,177,1)",
    pointBackgroundColor: "rgba(148,159,177,1)",
    pointBorderColor: "#fff",
  },
];

const labels = [
  "Address",
  "Jumeirah",
  "Cornad",
  "Hyatt",
  "Hilton Suites",
  "Hilton Convention",
  "Marriott",
  "DoubleTree",
];
const totalClicks = JSON.parse(localStorage.getItem("totalClicks") || "[]").map(
  Number
);

const datasets = [
  {
    label: "Total Clicks",
    ...colors[0],
    borderWidth: 0,
    data: totalClicks,
  },
  {
    label: "My Second dataset",
    ...colors[1],
    borderWidth: 0,
    data: [0, 0, 0, 0, 0, 0, 0],
  },
];

const data = {
  labels,
  datasets,
};



const dataMixed = {
  labels,
  datasets: [
    {
      label: "Sales",
      type: "line",
      data: [6, 5, 8, 8, 5, 5, 4, 9],
      borderWidth: 1,
      fill: false,
      ...colors[0],
      yAxisID: "y-axis-2",
    },
    {
      type: "bar",
      label: "Visitor",
      data: totalClicks,
      borderWidth: 1,
      ...colors[1],
      yAxisID: "y-axis-1",
    },
  ],
};

const options = {
  responsive: true,
  tooltips: {
    mode: "label",
  },
  elements: {
    line: {
      fill: false,
    },
  },
  scales: {
    xAxes: [
      {
        display: true,
        gridLines: {
          display: false,
        },
        labels,
      },
    ],
    yAxes: [
      {
        type: "linear",
        display: true,
        position: "left",
        id: "y-axis-1",
        gridLines: {
          display: false,
        },
        labels: {
          show: true,
        },
      },
      {
        type: "linear",
        display: true,
        position: "right",
        id: "y-axis-2",
        gridLines: {
          display: false,
        },
        labels: {
          show: true,
        },
      },
    ],
  },
};


const height = 180;

const mockChart = [
  {
    type: "bar",
    title: "Bar",
    subtitle: "Basic",
    data,
    height,
    options: {
      ...sharedOptions,
      ...gridOptions,
    },
  },
  {
    type: "horizontalbar",
    title: "Bar",
    subtitle: "Horizontal",
    data,
    height,
    options: {
      ...sharedOptions,
      ...gridOptions,
    },
  },
  {
    type: "bar",
    title: "Bar",
    subtitle: "Stacked",
    data: data,
    height: height,
    options: {
      ...sharedOptions,
      ...gridOptions,
      ...stackedGridOptions,
    },
  },
  {
    type: "line",
    title: "Line",
    subtitle: "Basic",
    // data: lineData,
    height: height,
    options: {
      ...sharedOptions,
      ...gridOptions,
    },
  },
  
];

export {
  sharedOptions,
  gridOptions,
  stackedGridOptions,
  colors,
  labels,
  datasets,
  data,
  // lineData,
  dataMixed,
  options,
  height,
  mockChart,
};
