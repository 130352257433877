

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import PropTypes from "prop-types";
import React from "react";

// const Map = withScriptjs(
//   withGoogleMap(props => (
//     <GoogleMap
//       defaultZoom={8}
//       defaultCenter={{ lat: props.lat, lng: props.lng }}
//       defaultOptions={{
//         scrollwheel: false,
//         zoomControl: false
//       }}
//     />
//   ))
// );

const MapCard = ({ title, subtitle, avatar, lat, lng, mapHeight }) => (
  <Card>
    <CardHeader avatar={avatar} title={title} subheader={subtitle} />
  </Card>
);

MapCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  avatar: PropTypes.element,
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  mapHeight: PropTypes.number.isRequired
};

export default MapCard;
