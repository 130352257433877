import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import { Box, Paper, Typography } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import api from "../../api";
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

function EditCategory({ open, handleClose, hotelData }) {
  // for image preview
  const [state, setstate] = useState("");

  const image = process.env.PUBLIC_URL + "/static/images/logo-dark.png";
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [formData, setFormData] = useState({
    en: "",
    ar: "",
    fr: "",
    ur: "",
    tr: "",
    sw: "",
    type: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  var loadFile = (event) => {
    if (event.target.files) {
      const file = event.target.files[0];
      setstate(URL.createObjectURL(event.target.files[0]));
      const fileURL = URL.createObjectURL(event.target.files[0]);
      setSelectedFile(file);
      console.log(fileURL);
    }
  };

  const handleUpdateHotel = async () => {
    try {
      setLoading(true);
      setError(null);

      const data = {
        name: {
          en: formData.en,
          ar: formData.ar,
          fr: formData.fr,
          ur: formData.ur,
          tr: formData.tr,
          sw: "",
        },
        type: formData.type,
      };

      const formDataToSend = new FormData();

      // Append each nested property individually to FormData
      Object.keys(data.name).forEach((key) => {
        formDataToSend.append(`name[${key}]`, data.name[key]);
      });

      formDataToSend.append("type", data.type);

      // for (let [key, value] of formDataToSend.entries()) {
      //   console.log(`${key}: ${value}`);
      // }

      if (selectedFile) {
        formDataToSend.append("image", selectedFile);
      }

      const token = sessionStorage.getItem("id_token");
      const response = await api.post(
        `/admin/categories/${hotelData.id}`,
        formDataToSend,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.success) {
        console.log("Category updated:", response.data.data);
        handleClose();
        setError("Category updated successfully");
        setSnackbarOpen(true);
      } else {
        setError("Error updating Category");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarOpen(true);
      console.error("Error updating Category:", error);
      setError("Error updating Category");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // This effect runs when hotelData changes
    if (hotelData) {
      // Set formData based on hotelData when it's available
      setFormData({
        en: hotelData && hotelData.name && hotelData.name.en,
        ar: hotelData && hotelData.name && hotelData.name.ar,
        fr: hotelData && hotelData.name && hotelData.name.fr,
        ur: hotelData && hotelData.name && hotelData.name.ur,
        tr: hotelData && hotelData.name && hotelData.name.tr,
        sw: hotelData && hotelData.name && hotelData.name.sw,
        type: hotelData && hotelData.type,
      });
    }
  }, [hotelData]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Editing Category Detail of{" "}
          {hotelData && hotelData.name && hotelData.name.en}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            You can edit important Category details or delete and add them
            again.
          </DialogContentText>

          <FormControl variant="standard" fullWidth>
            <InputLabel id="demo-simple-select-standard-label">
              Select Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              name="type"
              onChange={handleChange}
              error={formData.type === ""}
              value={formData.type}
              label="Category"
            >
              <MenuItem value="restaurant">Restaurant</MenuItem>
              <MenuItem value="mall">Mall</MenuItem>
              <MenuItem value="service">Service</MenuItem>
            </Select>
          </FormControl>

          <TextField
            autoFocus
            margin="dense"
            id="hotel-name"
            label="Category Name (English)"
            type="text"
            fullWidth
            name="en"
            defaultValue={hotelData && hotelData.name && hotelData.name.en}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="hotel-name"
            label="Category Name (Arabic)"
            type="text"
            fullWidth
            name="ar"
            value={formData.ar}
            defaultValue={hotelData && hotelData.name && hotelData.name.ar}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="hotel-name"
            label="Category Name (French)"
            type="text"
            fullWidth
            name="fr"
            defaultValue={hotelData && hotelData.name && hotelData.name.fr}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="hotel-name"
            label="Category Name (Urdu)"
            type="text"
            fullWidth
            name="ur"
            defaultValue={hotelData && hotelData.name && hotelData.name.ur}
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            id="hotel-name"
            label="Category Name (Turkish)"
            type="text"
            fullWidth
            name="tr"
            defaultValue={hotelData && hotelData.name && hotelData.name.tr}
            onChange={handleChange}
          />

          <Paper style={{ width: "150px", margin: "10px 0px" }}>
            <Box
              width="140px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <input
                type="file"
                accept="image/*"
                name="image"
                id="file"
                onChange={loadFile}
                style={{ display: "none" }}
              />
              <Typography
                variant="caption"
                style={{
                  fontSize: "12px",
                  padding: "5px 0px",
                  fontWeight: "Bold",
                }}
              >
                Category Main Image *
              </Typography>

              <img
                src={state ? state : image}
                id="output"
                width="100"
                name="outputmain"
                alt="test"
              />
            </Box>
            <Box display="flex" justifyContent="flex-end" padding="5px 10px">
              <label htmlFor="file" style={{ cursor: "pointer" }}>
                <PhotoCamera />
              </label>
            </Box>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpdateHotel} color="primary">
            Update
          </Button>
        </DialogActions>
        {loading && <LinearProgress />}
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        TransitionComponent={TransitionUp}
        message={error}
        key="snackbar"
        autoHideDuration={6000}
      />
    </div>
  );
}

export default EditCategory;
