import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Tooltip from "@material-ui/core/Tooltip";
import api from "../../api";
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";
import LinearProgress from "@material-ui/core/LinearProgress";

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

function EditSettings({ open, handleClose }) {
  const [settings, setSettings] = useState({
    android_version: "",
    ios_version: "",
    google_map_key: "",
    google_play_url: "",
    apple_store_url: "",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [formData, setFormData] = useState({
    androidversion: "",
    iosversion: "",
    googlemapkey: "",
    googleplayurl: "",
    applestoreurl: "",
  });

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        setLoading(true);
        setError(null);
        const token = sessionStorage.getItem("id_token");
        const response = await api.get("/customer/settings", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data.success) {
          setSettings(response.data.data);
        } else {
          setError("Failed to fetch settings");
          setSnackbarOpen(true);
        }
      } catch (error) {
        console.error("Error fetching settings:", error);
        setError("Failed to fetch settings");
        setSnackbarOpen(true);
      } finally {
        setLoading(false);
      }
    };

    fetchSettings();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleUpdateHotel = async () => {
    try {
      setLoading(true);
      setError(null);

      const data = {
        android_version: formData.androidversion,
        ios_version: formData.iosversion,
        google_map_key: formData.googlemapkey,
        google_play_url: formData.googleplayurl,
        apple_store_url: formData.applestoreurl,
      };

      const formDataToSend = new FormData();

      formDataToSend.append("android_version", data.android_version);
      formDataToSend.append("ios_version", data.ios_version);
      formDataToSend.append("google_map_key", data.google_map_key);
      formDataToSend.append("google_play_url", data.google_play_url);
      formDataToSend.append("apple_store_url", data.apple_store_url);

      // for (let [key, value] of formDataToSend.entries()) {
      //   console.log(`${key}: ${value}`);
      // }

      const token = sessionStorage.getItem("id_token");
      const response = await api.post(`/admin/settings`, formDataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.success) {
        console.log("Settings updated:", response.data.data);
        handleClose();
        setError("Settings updated successfully");
        setSnackbarOpen(true);
      } else {
        setError("Error updating Settings");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarOpen(true);
      console.error("Error updating Settings:", error);
      setError("Error updating Settings");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Edit Settings</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You can edit important settings details from here.
          </DialogContentText>
          <Tooltip title="Enter the version for the Google Play Store like 0.0.0">
            <TextField
              autoFocus
              margin="dense"
              id="shop-name"
              label="Android Version"
              type="text"
              fullWidth
              name="androidversion"
              placeholder="Enter version, e.g., 0.0.0"
              defaultValue={settings.android_version}
              onChange={handleChange}
            />
          </Tooltip>
          <Tooltip title="Enter the version for the iOS App Store like 0.0.0">
            <TextField
              margin="dense"
              id="shop-name"
              label="iOS Version"
              type="text"
              fullWidth
              name="iosversion"
              placeholder="Enter version, e.g., 0.0.0"
              defaultValue={settings.ios_version}
              onChange={handleChange}
            />
          </Tooltip>

          <Tooltip title="Enter last string u59qANlb1LE example:  https://www.youtube.com/watch?v=u59qANlb1LE">
            <TextField
              margin="dense"
              id="shop-name"
              label="Video Identifier"
              type="text"
              fullWidth
              name="googlemapkey"
              placeholder="Enter your Google Map API key"
              defaultValue={settings.google_map_key}
              onChange={handleChange}
            />
          </Tooltip>

          <Tooltip title="Enter the URL of the Google Play store for your shop">
            <TextField
              margin="dense"
              id="shop-name"
              label="Googleplay Url"
              type="text"
              fullWidth
              name="googleplayurl"
              placeholder="Enter the Google Play URL"
              defaultValue={settings.google_play_url}
              onChange={handleChange}
            />
          </Tooltip>

          <Tooltip title="Enter the URL of the Apple Store for your shop">
            <TextField
              margin="dense"
              id="shop-name"
              label="Apple Store URL"
              type="text"
              fullWidth
              name="applestoreurl"
              placeholder="Enter the Apple Store URL"
              defaultValue={settings.apple_store_url}
              onChange={handleChange}
            />
          </Tooltip>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpdateHotel} color="primary">
            Update Settings
          </Button>
        </DialogActions>
        {loading && <LinearProgress />}
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        TransitionComponent={TransitionUp}
        message={error}
        key="snackbar"
        autoHideDuration={6000}
      />
    </div>
  );
}

export default EditSettings;
