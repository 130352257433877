import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Input from "@material-ui/core/Input";
import TablePagination from "@material-ui/core/TablePagination";

const CustomTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.white,
    backgroundColor: "#9da07c",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const styles = (theme) => ({
  root: {
    paddingTop: theme.spacing(1) * 2,
    paddingBottom: theme.spacing(1) * 2,
    width: "100%",
    marginTop: theme.spacing(1) * 3,
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  iconButton: {
    padding: theme.spacing(0.5), // adjust padding as needed
  },
  cover: {
    width: 40,
    height: 25,
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
  appBar: {
    marginBottom: theme.spacing(1) * 3,
  },
  flexSpacer: {
    flex: 1,
  },
  searchInput: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: "200px",
    color: "black",
    "&:before": {
      borderBottom: "1px solid black",
    },
  },
});

function Options({ classes, option, handleClose, hotelData3 }) {
  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [hotels1, setHotels1] = useState([]);

  useEffect(() => {
    if (hotelData3 && Array.isArray(hotelData3)) {
      setHotels1(hotelData3);
    } else {
      console.error("Expected an array for hotelData3, but got:", hotelData3);
    }
  }, [hotelData3]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const filteredHotels = hotels1.filter((hotel1) =>
    hotel1.first_name.toLowerCase().includes(searchInput.toLowerCase())
  );

  return (
    <div>
      <Dialog
        open={option}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="xl"
      >
        <DialogContent>
          <Paper className={classes.root} elevation={1}>
            {/* {loading && <LinearProgress />} */}
            <Input
              placeholder="First Name ..."
              className={classes.searchInput}
              value={searchInput}
              onChange={handleSearchInputChange}
            />
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <CustomTableCell>S.No</CustomTableCell>
                  <CustomTableCell>First Name</CustomTableCell>
                  <CustomTableCell>Last Name</CustomTableCell>
                  <CustomTableCell>Phone</CustomTableCell>
                  <CustomTableCell>Email</CustomTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredHotels
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((hotel, index) => (
                    <TableRow className={classes.row} key={hotel.id}>
                      <CustomTableCell>
                        {page * rowsPerPage + index + 1}
                      </CustomTableCell>
                      <CustomTableCell>{hotel.first_name}</CustomTableCell>
                      <CustomTableCell>{hotel.last_name}</CustomTableCell>
                      <CustomTableCell>{hotel.phone}</CustomTableCell>
                      <CustomTableCell>{hotel.email}</CustomTableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filteredHotels.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
        {/* {loading && <LinearProgress />} */}
      </Dialog>
    </div>
  );
}
Options.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Options);
