import React,{useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import api from "../../../api";
import LinearProgress from "@material-ui/core/LinearProgress";
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";


function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

function EditUser({open, handleClose,hotelData}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };


  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
  });


  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleUpdateHotel = async () => {
    try {
      setLoading(true);
      setError(null);

      const data = {
        email: formData.email,
        name: formData.name,
        passowrd: formData.passowrd,
      };

      const formDataToSend = new FormData();

      formDataToSend.append("email", data.email);
      formDataToSend.append("name", data.name);
      formDataToSend.append("password", data.passowrd);
    

      const token = sessionStorage.getItem("id_token");
      const response = await api.post(
        `/admin/administrators/${hotelData.id}`,
        formDataToSend,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.success) {
        console.log("Admin updated:", response.data.data);
        handleClose();
        setError("Admin updated successfully");
        setSnackbarOpen(true);
      } else {
        setError("Error updating Admin");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarOpen(true);
      console.error("Error updating Admin:", error);
      setError("Error updating Admin");
    } finally {
      setLoading(false);
    }
  };


  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Edit Admin Detail of {hotelData.name}</DialogTitle>
        <DialogContent>
          <DialogContentText>
          To edit admin details, admin won't be notified. You can manually notify them via email.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            name="name"
            defaultValue={hotelData && hotelData.name}
            type="text"
            fullWidth
            onChange={handleChange}
          />
          <TextField
      
            margin="dense"
            id="email"
            name="email"
            defaultValue={hotelData && hotelData.email}
            label="Email Address"
            type="email"
            fullWidth
            onChange={handleChange}
          />
          

            
                  <TextField
            margin="dense"
            id="password"
            name="password"
            label="Password"
            type="text"
            fullWidth
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpdateHotel} color="primary">
            Update
          </Button>
        </DialogActions>
        {loading && <LinearProgress />}
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        TransitionComponent={TransitionUp}
        message={error}
        key="snackbar"
        autoHideDuration={6000}
      />
    </div>
  );
}

export default EditUser;
