import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import PropTypes from "prop-types";
import TableCell from "@material-ui/core/TableCell";
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import AddHotelSlider from "./AddHotelSlider";
import api from "../../api";
import DialogContentText from "@material-ui/core/DialogContentText";

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

const CustomTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.white,
    backgroundColor: "#9da07c",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const styles = (theme) => ({
  root: {
    paddingTop: theme.spacing(1) * 2,
    paddingBottom: theme.spacing(1) * 2,
    width: "100%",
    marginTop: theme.spacing(1) * 3,
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  iconButton: {
    padding: theme.spacing(0.5), // adjust padding as needed
  },
  cover: {
    width: 40,
    height: 25,
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
  appBar: {
    marginBottom: theme.spacing(1) * 3,
  },
  flexSpacer: {
    flex: 1,
  },
  searchInput: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: "200px",
    color: "white",
    "&:before": {
      borderBottom: "1px solid white",
    },
  },
});

function HotelSlider({
  classes,
  hotelslider,
  handleClose,
  hotelData,
  HotelID,
  hotelCallBack,
}) {
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [hotels, setHotels] = useState([]);
  const [hotelId, setHotelId] = useState(null);
  const [openadmin, setadminOpen] = useState(false);
  const [deleteHotelId, setDeleteHotelId] = useState(null);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  const handleNewAdminClick = (hotelId) => {
    setHotelId(hotelId);
    setadminOpen(true); // Open the dialog when edit icon is clicked
  };

  const childeUpdate = () => {
    hotelCallBack();
  };

  const handleDeleteClick = async (optionId) => {
    setDeleteHotelId(optionId);
    setDeleteConfirmationOpen(true);
  };
  const handleDeleteConfirm = async () => {
    try {
      setError(null);
      const token = sessionStorage.getItem("id_token");
      const response = await api.delete(`/admin/sliders/${deleteHotelId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.success) {
        // Handle success response, maybe show a success message or update UI accordingly
        setError("Hotel option successfully");
        console.log("Hotel option successfully");
        childeUpdate();
      } else {
        // Handle error response if needed
        setError("Error option hotel");
        console.error("Error option hotel:", response.data.error);
      }
    } catch (error) {
      setError("Error option hotel");
      console.error("Error option hotel:", error);
    }
    handleDeleteConfirmationClose();
  };

  const handleDeleteConfirmationClose = () => {
    setDeleteConfirmationOpen(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    // This effect runs when hotelData changes

    if (hotelData) {
      console.log("hotelData", hotelData);

      console.log(hotelData);
      // Convert hotelData object into an array of values
      const hotelOptions = Object.values(hotelData);
      setHotels(hotelOptions);
      console.log(hotelOptions);
    }
  }, [hotelData, deleteConfirmationOpen]);

  return (
    <div>
      <Dialog
        open={hotelslider}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="xl"
      >
        <DialogContent>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={() => handleNewAdminClick(HotelID)}
          >
            New Gallery
            <AddIcon className={classes.rightIcon} />
          </Button>
          <Paper className={classes.root} elevation={1}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <CustomTableCell>S.No</CustomTableCell>
                  <CustomTableCell>Image</CustomTableCell>
                  <CustomTableCell>Action</CustomTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {hotels.map((option, index) => (
                  <TableRow className={classes.row} key={option.id}>
                    <CustomTableCell>{index + 1}</CustomTableCell>
                    <CustomTableCell>
                      <CardMedia
                        className={classes.cover}
                        image={option.image}
                        title="Hotel Image"
                      />
                    </CustomTableCell>
                    <CustomTableCell>
                      <IconButton
                        className={classes.iconButton}
                        onClick={() => handleDeleteClick(option.id)}
                      >
                        <DeleteIcon color="secondary" />
                      </IconButton>
                    </CustomTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <AddHotelSlider
        openadmin={openadmin}
        setadminOpen={setadminOpen}
        hotelId={hotelId}
        handleClose={() => setadminOpen(false)}
        updateComponent={childeUpdate}
      />
      <Dialog
        open={deleteConfirmationOpen}
        onClose={handleDeleteConfirmationClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this image?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteConfirmationClose} color="primary">
            No
          </Button>
          <Button onClick={handleDeleteConfirm} color="secondary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        TransitionComponent={TransitionUp}
        message={<span id="message-id">{error}</span>}
        autoHideDuration={6000}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
      />
    </div>
  );
}
HotelSlider.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HotelSlider);
