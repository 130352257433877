// Pages
import {
  // AppBar,
  // Autocomplete,
  // Avatars,
  // BackendError,
  // Badges,
  // ButtonNavigation,
  // Buttons,
  // Calendar,
  // Cards,
  Chat,
  // Chips,
  // Dialogs,
  // Dividers,
  // Drawers,
  // ExpansionPanels,
  // GridList,
  Home,
  // Lists,
  // Lockscreen,
  // Media,
  // Menus,
  // Messages,
  // NotFound,
  // Paper,
  // PasswordReset,
  // Pickers,
  // Progress,
  // SelectionControls,
  // Selects,
  // Signin,
  // Signup,
  // Snackbars,
  // Social,
  // Steppers,
  // Tables,
  // Tabs,
  // TextFields,
  // Tooltips,
} from "./pages";
import Users from "./utils/demos/tables/Users";
import Admins from "./utils/demos/tables/Admins";
import GroupIcon from "@material-ui/icons/Group";
import AppsIcon from "@material-ui/icons/Apps";
// import EqualizerIcon from '@material-ui/icons/Equalizer';
import HotelIcon from "@material-ui/icons/Hotel";
import MuseumIcon from "@material-ui/icons/Museum";
// Icons
import LocalPharmacyIcon from "@material-ui/icons/LocalPharmacy";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
import SettingsIcon from "@material-ui/icons/Settings";
// import PersonIcon from '@material-ui/icons/Person';
import StoreIcon from "@material-ui/icons/Store";
import RestaurantIcon from "@material-ui/icons/Restaurant";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import CategoryIcon from "@material-ui/icons/Category";
import RestaurantMenuIcon from "@material-ui/icons/RestaurantMenu";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import NewsIcon from "@material-ui/icons/Description";
import LoyaltyIcon from "@material-ui/icons/Loyalty";
import ContactPageIcon from "@material-ui/icons/RecentActors";
import MonetizationOn from "@material-ui/icons/MonetizationOn";

import Hotels from "./pages/Pages/Hotels";
import Shop from "./pages/Pages/Shop";
import Food from "./pages/Pages/Food";
import Museum from "./pages/Pages/Museum";
import Events from "./pages/Pages/Events";
import Notifications from "./pages/Pages/Notifications";
import MallCategory from "./pages/Pages/MallCategory";
import RestaurantCategory from "./pages/Pages/RestaurantCategory";
import ServiceCategory from "./pages/Pages/ServiceCategory";
import Services from "./pages/Pages/Services";
import Settings from "./pages/Pages/Settings";
import News from "./pages/Pages/News";
import Benefits from "./pages/Pages/Benefits";
import Contacts from "./pages/Pages/Contacts";
import Investment from "./pages/Pages/Investment";

export default {
  items: [
    {
      path: "/",
      name: "Home",
      type: "link",
      icon: AppsIcon,
      component: Home,
    },
    {
      path: "/user",
      name: "Users",
      type: "submenu",
      icon: GroupIcon,
      children: [
        {
          path: "/users",
          name: "Users",
          component: Users,
        },
        {
          path: "/admins",
          name: "Admins",
          component: Admins,
        },
      ],
    },
    {
      path: "/hotels",
      name: "Hotels",
      type: "link",
      icon: HotelIcon,
      component: Hotels,
    },
    {
      path: "/investment",
      name: "Investment",
      type: "link",
      icon: MonetizationOn,
      component: Investment,
    },
    {
      path: "/contact",
      name: "Contact",
      type: "link",
      icon: ContactPageIcon,
      component: Contacts,
    },
    {
      path: "/benefits",
      name: "Benefits",
      type: "link",
      icon: LoyaltyIcon,
      component: Benefits,
    },
    {
      path: "/news",
      name: "News",
      type: "link",
      icon: NewsIcon,
      component: News,
    },
    {
      path: "/shops",
      name: "Shops",
      type: "link",
      icon: StoreIcon,
      component: Shop,
    },
    {
      path: "/shopcategories",
      name: "Shop Categories",
      type: "link",
      icon: CategoryIcon,
      component: MallCategory,
    },
    {
      path: "/food",
      name: "Food & Beverages",
      type: "link",
      icon: RestaurantIcon,
      component: Food,
    },
    {
      path: "/foodcategories",
      name: "F & B Categories",
      type: "link",
      icon: RestaurantMenuIcon,
      component: RestaurantCategory,
    },
    {
      path: "/services",
      name: "Services",
      type: "link",
      icon: LocalPharmacyIcon,
      component: Services,
    },
    {
      path: "/servicecategories",
      name: "Service Categories",
      type: "link",
      icon: AccountBalanceIcon,
      component: ServiceCategory,
    },
    {
      path: "/museum",
      name: "Museum",
      type: "link",
      icon: MuseumIcon,
      component: Museum,
    },
    {
      path: "/events",
      name: "Events",
      type: "link",
      icon: EmojiEventsIcon,
      component: Events,
    },
    {
      path: "/chats",
      name: "Chat",
      type: "link",
      icon: QuestionAnswerIcon,
      badge: {
        type: "error",
        value: "7",
      },
      component: Chat,
    },
    {
      path: "/notifications",
      name: "Notifications",
      type: "link",
      icon: LocalLibraryIcon,
      component: Notifications,
    },
    {
      path: "/settings",
      name: "Settings",
      type: "link",
      icon: SettingsIcon,
      component: Settings,
    },
    // {
    //   path: '/apps',
    //   name: 'Apps',
    //   type: 'submenu',
    //   icon: AppsIcon,
    //   badge: {
    //     type: 'primary',
    //     value: '5'
    //   },
    //   children: [
    //     {
    //       path: '/calendar',
    //       name: 'Calendar',
    //       component: Calendar
    //     },
    //     {
    //       path: '/media',
    //       name: 'Media',
    //       component: Media
    //     },
    //     {
    //       path: '/messages',
    //       name: 'Messages',
    //       component: Messages
    //     },
    //     {
    //       path: '/social',
    //       name: 'Social',
    //       component: Social
    //     },
    //     {
    //       path: '/chat',
    //       name: 'Chat',
    //       component: Chat
    //     }
    //   ]
    // },
    // {
    //   path: '/material',
    //   name: 'Material',
    //   type: 'submenu',
    //   icon: EqualizerIcon,
    //   badge: {
    //     type: 'error',
    //     value: '10'
    //   },
    //   children: [
    //     {
    //       path: '/appbar',
    //       name: 'App Bar',
    //       component: AppBar
    //     },
    //     {
    //       path: '/autocomplete',
    //       name: 'Auto Complete',
    //       component: Autocomplete
    //     },
    //     {
    //       path: '/avatars',
    //       name: 'Avatars',
    //       component: Avatars
    //     },
    //     {
    //       path: '/badges',
    //       name: 'Badges',
    //       component: Badges
    //     },
    //     {
    //       path: '/button-navigation',
    //       name: 'Button Navigation',
    //       component: ButtonNavigation
    //     },
    //     {
    //       path: '/buttons',
    //       name: 'Buttons',
    //       component: Buttons
    //     },
    //     {
    //       path: '/cards',
    //       name: 'Cards',
    //       component: Cards
    //     },
    //     {
    //       path: '/chips',
    //       name: 'Chips',
    //       component: Chips
    //     },
    //     {
    //       path: '/dialogs',
    //       name: 'Dialogs',
    //       component: Dialogs
    //     },
    //     {
    //       path: '/dividers',
    //       name: 'Dividers',
    //       component: Dividers
    //     },
    //     {
    //       path: '/drawers',
    //       name: 'Drawers',
    //       component: Drawers
    //     },
    //     {
    //       path: '/expansion-panels',
    //       name: 'Expansion Panels',
    //       component: ExpansionPanels
    //     },
    //     {
    //       path: '/grid-list',
    //       name: 'Grid List',
    //       component: GridList
    //     },
    //     {
    //       path: '/lists',
    //       name: 'Lists',
    //       component: Lists
    //     },
    //     {
    //       path: '/menus',
    //       name: 'Menus',
    //       component: Menus
    //     },
    //     {
    //       path: '/paper',
    //       name: 'Paper',
    //       component: Paper
    //     },
    //     {
    //       path: '/pickers',
    //       name: 'Pickers',
    //       component: Pickers
    //     },
    //     {
    //       path: '/progress',
    //       name: 'Progress',
    //       component: Progress
    //     },
    //     {
    //       path: '/selection-controls',
    //       name: 'Selection Controls',
    //       component: SelectionControls
    //     },
    //     {
    //       path: '/selects',
    //       name: 'Selects',
    //       component: Selects
    //     },
    //     {
    //       path: '/snackbars',
    //       name: 'Snackbars',
    //       component: Snackbars
    //     },
    //     {
    //       path: '/steppers',
    //       name: 'Steppers',
    //       component: Steppers
    //     },
    //     {
    //       path: '/tables',
    //       name: 'Tables',
    //       component: Tables
    //     },
    //     {
    //       path: '/tabs',
    //       name: 'Tabs',
    //       component: Tabs
    //     },
    //     {
    //       path: '/text-fields',
    //       name: 'Text Fields',
    //       component: TextFields
    //     },
    //     {
    //       path: '/tooltips',
    //       name: 'Tooltips',
    //       component: Tooltips
    //     }
    //   ]
    // },
    // {
    //   name: 'Authentication',
    //   type: 'submenu',
    //   icon: PersonIcon,
    //   children: [
    //     {
    //       path: '/signin',
    //       name: 'Signin',
    //       component: Signin
    //     },
    //     {
    //       path: '/signup',
    //       name: 'Signup',
    //       component: Signup
    //     },
    //     {
    //       path: '/forgot',
    //       name: 'Forgot',
    //       component: PasswordReset
    //     },
    //     {
    //       path: '/lockscreen',
    //       name: 'Lockscreen',
    //       component: Lockscreen
    //     }
    //   ]
    // },
    // {
    //   name: 'Error',
    //   type: 'submenu',
    //   icon: FaceIcon,
    //   children: [
    //     {
    //       path: '/404',
    //       name: '404',
    //       component: NotFound
    //     },
    //     {
    //       path: '/500',
    //       name: 'Error',
    //       component: BackendError
    //     }
    //   ]
    // }
  ],
};
