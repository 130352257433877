// api.js
import axios from 'axios';
// rember in baseUrl dont add a slash / in the end of the api 
const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export default api;
