import React,{useState,useEffect} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import api from "../../../api";
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";
import LinearProgress from "@material-ui/core/LinearProgress";

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}
const useStyles = makeStyles(theme => ({
   
    leftAlign: {
      textAlign: 'left',
      marginTop: '20px'
    }
  }));

function EditUser({open, hotelData ,handleClose}) {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    gender: "",
    password: "",
  });


  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };


      const handleUpdateHotel = async () => {
        try {
          setLoading(true);
          setError(null);
    
          const data = {
            name: formData.name,
            email: formData.email,
            mobile: formData.mobile,
            gender: formData.gender,
            password: formData.password,
          };
    
          const formDataToSend = new FormData();
    
    
          formDataToSend.append("name", data.name);
          formDataToSend.append("email", data.email);
          formDataToSend.append("mobile", data.mobile);
          formDataToSend.append("gender", data.gender);
          formDataToSend.append("password", data.password);

          // for (let [key, value] of formDataToSend.entries()) {
          //   console.log(`${key}: ${value}`);
          // }
    
          const token = sessionStorage.getItem("id_token");
          const response = await api.post(
            `/admin/customers/${hotelData.id}`,
            formDataToSend,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );
    
          if (response.data.success) {
            console.log("User updated:", response.data.data);
            handleClose();
            setError("User updated successfully");
            setSnackbarOpen(true);
          } else {
            setError("Error updating User");
            setSnackbarOpen(true);
          }
        } catch (error) {
          setSnackbarOpen(true);
          console.error("Error updating User:", error);
          setError("Error updating User");
        } finally {
          setLoading(false);
        }
      };

      useEffect(() => {
        // This effect runs when hotelData changes
        if (hotelData) {
          // Set formData based on hotelData when it's available
          setFormData({
            name: hotelData && hotelData.name,
            email: hotelData && hotelData.email,
            mobile: hotelData && hotelData.mobile,
            gender: hotelData && hotelData.gender,
          });
        }
      }, [hotelData]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Edit User Detail of {hotelData.name}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To edit user detail the user will no be notifed you can notified the user manully by email.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            name="name"
            label="Name"
            type="text"
            fullWidth
            defaultValue={hotelData && hotelData.name}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="email"
            name="email"
            label="Email Address"
            type="email"
            fullWidth
            defaultValue={hotelData && hotelData.email}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="mobile"
            name="mobile"
            label="Mobile"
            type="text"
            fullWidth
            defaultValue={hotelData && hotelData.mobile}
            onChange={handleChange}
          />

             <FormLabel component="legend" className={classes.leftAlign}>Gender</FormLabel>
                  <RadioGroup
                    aria-label="Gender"
                    name="gender"
                    className={classes.group}
                    defaultValue={hotelData && hotelData.gender}
                    onChange={handleChange}
                  >
                     <FormControlLabel value="male" control={<Radio />} label="Male" />
                    <FormControlLabel value="female" control={<Radio />} label="Female" />
                   
                  </RadioGroup>
                  <TextField
            margin="dense"
            id="password"
            name="password"
            label="Password"
            onChange={handleChange}
            type="text"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpdateHotel} color="primary">
            Update
          </Button>
        </DialogActions>
        {loading && <LinearProgress />}
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        TransitionComponent={TransitionUp}
        message={error}
        key="snackbar"
        autoHideDuration={6000}
      />
    </div>
  );
}

export default EditUser;
