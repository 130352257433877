import { Bar } from "react-chartjs-2";
import {
  NewsCard,
  StatCard,
  Wrapper
} from "../../components";
import React, { useState , useEffect} from "react";
import { mockDashboard, mockFeed } from "../../utils/mock";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import EmailIcon from "@material-ui/icons/Email";
// import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
// import LinearProgress from "@material-ui/core/LinearProgress";
// import ListItemIcon from "@material-ui/core/ListItemIcon";
// import ListItemText from "@material-ui/core/ListItemText";
// import Menu from "@material-ui/core/Menu";
// import MenuItem from "@material-ui/core/MenuItem";
// import MoreIcon from "@material-ui/icons/More";
// import MoreVertIcon from "@material-ui/icons/MoreVert";
// import NotificationsOffIcon from "@material-ui/icons/NotificationsOff";
import Paper from "@material-ui/core/Paper";
// import SettingsIcon from "@material-ui/icons/Settings";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import MuseumIcon from '@material-ui/icons/Museum';
import GroupIcon from '@material-ui/icons/Group';
import HotelIcon from '@material-ui/icons/Hotel';
import StoreIcon from '@material-ui/icons/Store';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import LocalPharmacyIcon from "@material-ui/icons/LocalPharmacy";
import RestaurantIcon from '@material-ui/icons/Restaurant';
import api from "../../api";

let id = 0;
function createData(name, date, progress) {
  id += 1;
  return { id, name, date, progress };
}

const data = [
  createData("The Ministry of Finance approval", "January 24"),
  createData("New Jabal Omar project in Makkah", "February 2"),
  createData("New CFO of Jabal Omar", "March 30"),
  createData("General manager of Jabal Omar Jumeirah", "April 12"),
];

const Home = () => {
  const [customersCount, setCustomersCount] = useState(0); // State to store the number of customers
  const [hotelsCount, setHotelsCount] = useState(0);
  const [shopsCount, setShopsCount] = useState(0);
  const [contactsCount, setContactsCount] = useState(0);
  const [eventsCount, setEventsCount] = useState(0);
  const [servicesCount, setServicesCount] = useState(0);
  const [restaurantsCount, setRestaurantsCount] = useState(0);

  useEffect(() => {
    // Fetch customer data
    const fetchCustomers = async () => {
      try {
        const token = sessionStorage.getItem("id_token");
        const response = await api.get("/admin/customers", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.success && response.data.data) {
          setCustomersCount(response.data.data.length);
        } else {
          setCustomersCount(0);
        }
      } catch (error) {
        console.error("Error fetching customer data", error);
        setCustomersCount(0);
      }
    };

    const fetchHotels = async () => {
      try {
        const token = sessionStorage.getItem("id_token");
        const response = await api.get("/admin/hotels", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.success && response.data.data) {
          setHotelsCount(response.data.data.length); // Set the count of hotels
        } else {
          setHotelsCount(0); // Set count to 0 if no data or success is false
        }
      } catch (error) {
        console.error("Error fetching hotel data", error);
        setHotelsCount(0); // Set to 0 in case of error
      }
    };

    // Fetch shops data
    const fetchShops = async () => {
      try {
        const token = sessionStorage.getItem("id_token");
        const response = await api.get("/admin/malls", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.success && response.data.data) {
          setShopsCount(response.data.data.length); // Set the count of shops
        } else {
          setShopsCount(0); // Set count to 0 if no data or success is false
        }
      } catch (error) {
        console.error("Error fetching mall data", error);
        setShopsCount(0); // Set to 0 in case of error
      }
    };

    // Fetch contact forms data
    const fetchContacts = async () => {
      try {
        const token = sessionStorage.getItem("id_token");
        const response = await api.get("/admin/contacts", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.success && response.data.data) {
          setContactsCount(response.data.data.length); // Set the count of contacts
        } else {
          setContactsCount(0); // Set count to 0 if no data or success is false
        }
      } catch (error) {
        console.error("Error fetching contact forms data", error);
        setContactsCount(0); // Set to 0 in case of error
      }
    };

    // Fetch events data
    const fetchEvents = async () => {
      try {
        const token = sessionStorage.getItem("id_token");
        const response = await api.get("/admin/events", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.success && response.data.data) {
          setEventsCount(response.data.data.length); // Set the count of events
        } else {
          setEventsCount(0); // Set count to 0 if no data or success is false
        }
      } catch (error) {
        console.error("Error fetching events data", error);
        setEventsCount(0); // Set to 0 in case of error
      }
    };

    // Fetch services data
    const fetchServices = async () => {
      try {
        const token = sessionStorage.getItem("id_token");
        const response = await api.get("/admin/services", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.success && response.data.data) {
          setServicesCount(response.data.data.length); // Set the count of services
        } else {
          setServicesCount(0); // Set count to 0 if no data or success is false
        }
      } catch (error) {
        console.error("Error fetching services data", error);
        setServicesCount(0); // Set to 0 in case of error
      }
    };

    // Fetch restaurant data
    const fetchRestaurants = async () => {
      try {
        const token = sessionStorage.getItem("id_token");
        const response = await api.get("/admin/restaurants", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.success && response.data.data) {
          setRestaurantsCount(response.data.data.length); // Set the count of restaurants
        } else {
          setRestaurantsCount(0); // Set count to 0 if no data or success is false
        }
      } catch (error) {
        console.error("Error fetching restaurant data", error);
        setRestaurantsCount(0); // Set to 0 in case of error
      }
    };

    fetchCustomers(); // Fetch customer data
    fetchHotels(); // Fetch hotel data
    fetchShops();
    fetchContacts();
    fetchEvents();
    fetchServices();
    fetchRestaurants();
  }, []); // Empty dependency array to call it only once on component mount

  // const [anchorEl, setAnchorEl] = useState(null);

  // const handleClick = event => setAnchorEl(event.currentTarget);
  // const handleClose = () => setAnchorEl(null);

  // const chartMenu = (
  //   <Menu
  //     id="chart-menu"
  //     anchorEl={anchorEl}
  //     open={Boolean(anchorEl)}
  //     onClose={handleClose}
  //   >
  //     <MenuItem onClick={handleClose}>
  //       <ListItemIcon>
  //         <SettingsIcon />
  //       </ListItemIcon>
  //       <ListItemText primary="Settings" />
  //     </MenuItem>
  //     <MenuItem onClick={handleClose}>
  //       <ListItemIcon>
  //         <MoreIcon />
  //       </ListItemIcon>
  //       <ListItemText primary="View more" />
  //     </MenuItem>
  //     <MenuItem onClick={handleClose}>
  //       <ListItemIcon>
  //         <NotificationsOffIcon />
  //       </ListItemIcon>
  //       <ListItemText primary="Disable notifications" />
  //     </MenuItem>
  //     <MenuItem onClick={handleClose}>
  //       <ListItemIcon>
  //         <ExitToAppIcon />
  //       </ListItemIcon>
  //       <ListItemText primary="Remove panel" />
  //     </MenuItem>
  //   </Menu>
  // );

  return (
    <Wrapper>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            type="fill"
            title="Users"
            value={customersCount}
            icon={<GroupIcon />}
            color="#374c5f"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            type="fill"
            title="Hotels"
            value={hotelsCount}
            icon={<HotelIcon />}
            color="#9da07c"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            type="fill"
            title="Shops"
            value={shopsCount}
            icon={<StoreIcon />}
            color="#374c5f"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            type="fill"
            title="Contact Forms"
            value={contactsCount}
            icon={<EmailIcon />}
            color="#9da07c"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            type="fill"
            title="Museum"
            value={1}
            icon={<MuseumIcon />}
            color="#9da07c"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            type="fill"
            title="Events"
            value={eventsCount}
            icon={<EmojiEventsIcon />}
            color="#374c5f"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            type="fill"
            title="Services"
            value={servicesCount}
            icon={<LocalPharmacyIcon />}
            color="#9da07c"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            type="fill"
            title="Food & Beverages"
            value={restaurantsCount}
            icon={<RestaurantIcon />}
            color="#374c5f"
          />
        </Grid>
        {/* {chartMenu} */}
        {mockDashboard.map((chart, index) => (
          <Grid item xs={12} sm={12} md={6} key={index}>
            <Card>
              <CardHeader
                subheader={chart.title}
                action={
                  <IconButton id={`${index}-menu-button`}>
                    {/* <MoreVertIcon /> */}
                  </IconButton>
                }
              />
              <CardContent>
                {chart.type === "bar" && (
                  <Bar
                    data={chart.data}
                    height={chart.height}
                    options={chart.options}
                  />
                )}
                {/* {chart.type === "bubble" && (
                  <Bubble
                    data={chart.data}
                    height={chart.height}
                    options={chart.options}
                  />
                )} */}
              </CardContent>
            </Card>
          </Grid>
        ))}
        <Grid item xs={12} sm={12} md={6}>
          <Paper className="table-responsive">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Notifications</TableCell>
                  <TableCell>Date</TableCell>
                  {/* <TableCell>Current Progress</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((n) => (
                  <TableRow key={n.id}>
                    <TableCell component="th" scope="row">
                      {n.name}
                    </TableCell>
                    <TableCell>{n.date}</TableCell>
                    {/* <TableCell>
                      {
                        <LinearProgress
                          variant="determinate"
                          value={n.progress}
                        />
                      }
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <NewsCard subtitle="Last updated 24 mins ago" feed={mockFeed} />
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default Home;
