import React,{useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from "@material-ui/core/LinearProgress";
import api from "../../../api";
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";


function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

function EditUser({openadmin, handleClose}) {

  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleAddHotel = async () => {
    try {
      setLoading(true);
      setError(null);

      const data = {
        name: formData.name,
        email: formData.email,
        password: formData.password,
      };

      const formDataToSend = new FormData();

  

      formDataToSend.append("name", data.name);
      formDataToSend.append("email", data.email);
      formDataToSend.append("password", data.password);

      
        const token = sessionStorage.getItem("id_token");
        const response = await api.post("/admin/administrators", formDataToSend, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.data.success) {
          console.log("New Admin added:", response.data.data);

          setFormData({
            name: "",
            email: "",
            password: "",
          });
          handleClose(); // Close dialog after successful addition
          setError("New Admin added");
          setSnackbarOpen(true);
        }
  
    } catch (error) {
      setSnackbarOpen(true);
      console.error("Error adding admin:", error);
      setError("Error adding admin");
    } finally {
      setLoading(false);
    }
  };

  return (   
    <div>
      <Dialog
        open={openadmin}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">New Admin Detail</DialogTitle>
        <DialogContent>
          <DialogContentText>
          To add a new admin just enter the following details all are required fields
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            name="name"
            error={formData.name === ""}
            value={formData.name}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="email"
            label="Email Address"
            type="email"
            fullWidth
            name="email"
            error={formData.email === ""}
            value={formData.email}
            onChange={handleChange}
          />
          

            
          <TextField
            margin="dense"
            id="password"
            label="Password"
            type="text"
            fullWidth
            name="password"
            error={formData.password === ""}
            value={formData.password}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddHotel} color="primary">
            Add
          </Button>
        </DialogActions>
        {loading && <LinearProgress />}
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        TransitionComponent={TransitionUp}
        message={<span id="message-id">{error}</span>}
        autoHideDuration={6000}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
      />
    </div>
  );
}

export default EditUser;
